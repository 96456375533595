import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ExpandedItem from "./ExpandedItem";
import { ItemListProps } from "./model";
import styled from "@emotion/styled";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { GridItem } from "./GridItem";
import { Product, ROUTES } from "models/model";
import ItemListSectionDecorator from "./ItemListSectionDecorator";

const ExpandedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItemList = ({ products, onAddToOrder }: ItemListProps) => {
  const navigate = useNavigate();
  let location = useLocation();
  const match = useMatch(`${ROUTES.EXPANDED_ITEM}/:itemId`);

  const listRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [expandedItem, setExpandedItem] = useState<Product | null>(null);

  useEffect(() => {
    if (products.length) {
      if (match) {
        const product = products.find((p) => p.name === match.params.itemId);
        if (product) {
          return setExpandedItem(product);
        } else {
          navigate(ROUTES.EXPANDED_ITEM);
        }
      } else {
        if (location.pathname !== "/") {
          navigate("/");
        }
        setExpandedItem(null);
      }
    }
    console.log(location);
  }, [location, products.length]);

  useLayoutEffect(() => {
    if (listRef.current) {
      setDimensions({
        width: listRef.current.clientWidth,
        height: listRef.current.clientHeight,
      });
    }
  }, [listRef?.current?.clientWidth]);

  const onCloseItem = () => {
    navigate(-1);
    setExpandedItem(null);
  };
  const itemClickHandler = (item: Product) => {
    setExpandedItem(item);
    navigate(`${ROUTES.EXPANDED_ITEM}/${encodeURIComponent(item?.name)}`);
  };

  return (
    <>
      <div ref={listRef} className="m-2 flex flex-col items-center max-w-3xl">
        <ItemListSectionDecorator />
        <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 p-1 container">
          {products.map((item, i) => (
            <GridItem
              key={i}
              index={i}
              dimensions={dimensions}
              product={item}
              onClick={() => itemClickHandler(item)}
            />
          ))}
        </div>
      </div>
      <ExpandedContainer>
        <Modal
          dir="ltr"
          className="flex justify-center md:m-10 overflow-auto"
          open={!!expandedItem}
          onClose={onCloseItem}
        >
          <div>
            <ExpandedItem {...{ expandedItem, onCloseItem, onAddToOrder }} />
          </div>
        </Modal>
      </ExpandedContainer>
    </>
  );
};

export default ItemList;
