import { Button, List, Popover } from "@mui/material";
import { ADDON_TEXTS, OrderBag, PRICES, ADDONS } from "models/model";
import Divider from "@mui/material/Divider";
import styled from "@emotion/styled";
import { getBagSum } from "utils/utils";
import { useState } from "react";
import SendOrderForm from "./SendOrderForm";
import { ChevronLeftIcon, TrashIcon } from "@heroicons/react/solid";

interface CartProps {
  bag: OrderBag;
  setBag: React.Dispatch<React.SetStateAction<OrderBag>>;
  anchorEl: HTMLButtonElement | null;
  onSendOrder: () => void;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

const ItemList = styled(List)`
  width: 500px;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
`;
const CartItem = styled.div`
  width: 100%;
  text-align: right;
  padding: 10px 0px;
`;
const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 25px;
  line-height: 25px;
`;
const EmptyBagContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 30vh 0px;
  color: rgb(85, 85, 85);
`;
const OrderButtonRow = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ItemDivider = styled(Divider)`
  margin-top: 10px;
`;
export const Cart = ({
  anchorEl,
  setAnchorEl,
  bag,
  setBag,
  onSendOrder,
}: CartProps) => {
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [step, setStep] = useState(0);
  const bagSum = getBagSum(bag);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const removeItem = (index: number) => {
    const newOrderItems = [...bag.orderItems];
    newOrderItems.splice(index, 1);
    setBag({
      ...bag,
      orderItems: newOrderItems,
    });
  };
  const onChangeCity = (value: string | null) => {
    setBag({
      ...bag,
      city: value as string,
    });
  };
  const onChangeFullName = (value: string) => {
    setBag({
      ...bag,
      fullName: value,
    });
  };

  return (
    <Popover
      dir="rtl"
      id={id}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {step === 0 && (
        <>
          <ItemList id="itemList">
            {bag.orderItems.map((item, i) => (
              <CartItem key={`${item.product.name}_${i}`}>
                <ItemRow
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TrashIcon
                      className="h-4 w-4 ml-1 -mr-1 text-gray-400"
                      onClick={() => removeItem(i)}
                    />
                    <span className="font-semibold"> {item.product.name}</span>
                    {item.count > 1 && (
                      <div style={{ marginRight: "5px", fontWeight: "bold" }}>
                        {item.count}X
                      </div>
                    )}
                    {item.pricedOption && (
                      <span className="text-sm mr-2">
                        {item.pricedOption?.key}
                      </span>
                    )}
                    {item.options.map((o) => (
                      <span className="text-sm mr-2">{o}</span>
                    ))}
                  </div>
                  <div style={{ fontSize: 18 }}>
                    ₪ {item.pricedOption?.price || item.product.price}
                  </div>
                </ItemRow>
                {item.addons.map((addon) => (
                  <div key={addon}>
                    <ItemRow>
                      <span className="text-sm">{ADDON_TEXTS[addon]}</span>
                      <span className="text-sm mr-2">₪ {PRICES[addon]}</span>
                    </ItemRow>
                    {addon === ADDONS.ADDTEXT && !!item.itemText && (
                      <span className="text-xs text-slate-700">
                        "{item.itemText}"
                      </span>
                    )}
                  </div>
                ))}
                {!!item.comments && (
                  <span className="text-sm text-slate-600 font-bold">
                    הערות למוצר:{" "}
                    <span className="font-normal">{item.comments}</span>
                  </span>
                )}
                <ItemDivider />
              </CartItem>
            ))}
            <ItemRow style={{ fontWeight: "bold" }}>
              <div>סה״כ</div>
              <div>₪ {bagSum}</div>
            </ItemRow>
            {!bag.orderItems.length && (
              <EmptyBagContainer>העגלה ריקה :(</EmptyBagContainer>
            )}
          </ItemList>
          <OrderButtonRow>
            <Button
              className="px-4"
              endIcon={<ChevronLeftIcon className="h-5 w-5 mr-1 text-white" />}
              variant="contained"
              disableElevation
              disabled={!bag.orderItems.length}
              size="medium"
              onClick={() => setStep(1)}
            >
              <span className="text-base"> המשך</span>
            </Button>
          </OrderButtonRow>
        </>
      )}
      {step === 1 && (
        <SendOrderForm
          {...{ onSendOrder, setStep, onChangeCity, onChangeFullName }}
        />
      )}
    </Popover>
  );
};
