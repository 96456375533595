import { useState } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { Button } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
interface CarouselProps {
  imgs: Array<string | undefined>;
}
const Carousel = ({ imgs }: CarouselProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = imgs.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  return (
    <div className="relative">
      <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
        {imgs.map((img, key) => (
          <img
            className="w-full m-auto object-contain p-2 aspect-square md:max-w-xs"
            src={img}
            alt={img}
            key={`${img}-${key}`}
          />
        ))}
      </SwipeableViews>
      <MobileStepper
        className="w-full absolute bottom-0 bg-transparent"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </div>
  );
};

export default Carousel;
