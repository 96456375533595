import { ArrowBack } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import { ADDONS, COLORS, PricedOption } from "models/model";
import { PricingSection } from "./PricingSection";
import {
  getIsAddEnabled,
  getOptionsLists,
  getPricedOptions,
} from "utils/utils";
import { ExpandedItemProps, initialPriceState } from "./model";
import { useReducerWrapper } from "models/pricing-reducer";
import { useEffect, useRef } from "react";
import Carousel from "./Carousel";

const ExpandedItem = ({
  expandedItem,
  onCloseItem,
  onAddToOrder,
}: ExpandedItemProps) => {
  const { state, dispatch } = useReducerWrapper(initialPriceState);
  const commentTextArea = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    // navigate(expandedItem?.name || '');
  }, []);

  if (!expandedItem) return <div>loading</div>;

  const pricedOptions = getPricedOptions(expandedItem.sizedPrice);

  const addToOrderHandler = () => {
    const { option1, option2, option3, pricedOption } = state;
    const addons = [];
    if (state.isGiftAdded) addons.push(ADDONS.GIFT);
    if (state.isTextAdded) addons.push(ADDONS.ADDTEXT);

    let newPricedOption: PricedOption | null = null;

    if (pricedOption) {
      const price = pricedOptions?.find((p) => p.size === pricedOption)?.price;
      newPricedOption = { key: pricedOption, price: Number(price) };
    }

    onAddToOrder({
      count: +state.count,
      product: expandedItem,
      addons,
      itemText: state.itemText,
      options: [option1, option2, option3],
      pricedOption: newPricedOption,
      comments: commentTextArea?.current?.value,
    });
    onCloseItem();
  };

  const disabled = getIsAddEnabled(
    state,
    getOptionsLists(expandedItem),
    getPricedOptions(expandedItem.sizedPrice)
  );
  const { image, image2, image3, image4 } = expandedItem;
  return (
    <div className="bg-white overflow-auto h-screen md:w-[700px]">
      <div className="w-full m-2 fixed z-10">
        <IconButton onClick={onCloseItem}>
          <ArrowBack />
        </IconButton>
      </div>
      {image2 ? (
        <Carousel imgs={[image, image2, image3, image4].filter((i) => !!i)} />
      ) : (
        <img
          alt={expandedItem.name}
          src={expandedItem.image}
          className="w-full m-auto object-contain p-2 aspect-square md:max-w-xs"
        />
      )}
      <Stack alignItems="center" className="m-3">
        <p className="text-slate-800 text-xl font-medium">
          {expandedItem.name}
        </p>
        <div
          dir="rtl"
          className="text-sm text-slate-600 my-2 text-center"
          dangerouslySetInnerHTML={{ __html: expandedItem.description }}
        />
        <div
          style={{
            width: "100%",
            height: 1,
            background: COLORS.dividerGray,
            marginBottom: 15,
          }}
        />
        <PricingSection
          product={expandedItem}
          pricedOptions={pricedOptions}
          state={state}
          dispatch={dispatch}
        />
        <textarea
          dir="rtl"
          rows={1}
          ref={commentTextArea}
          className="w-full my-2 p-2 border border-violet-300-300 rounded outline-1 outline-purple-900"
          placeholder="הערות להזמנה"
        />
        <Button
          variant="contained"
          disableElevation
          disabled={disabled}
          size="large"
          className="tracking-wider"
          style={{
            opacity: disabled ? 0.2 : 1,
            borderRadius: 0,
            height: 50,
          }}
          fullWidth
          onClick={addToOrderHandler}
        >
          הוספה לסל
        </Button>
        <div dir="rtl" className="text-slate-400 text-sm mt-2">
          * ניצור איתכם קשר לאחר ההזמנה לגבי שליחת התמונות
        </div>
      </Stack>
      <div style={{ height: 100 }}></div>
    </div>
  );
};

export default ExpandedItem;
