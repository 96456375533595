import {
  collection,
  Firestore,
  getDocs,
  getFirestore,
} from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { useEffect, useRef } from "react";

export const useFirestore = () => {
  const dbRef = useRef<Firestore>();
  useEffect(() => {
    initializeApp({
      apiKey: "AIzaSyChdBAE8r0Lzj_97MRUMb-JfEwi8xV3Cy4",
      authDomain: "https://photo-print-mobile.firebaseio.com",
      projectId: "photo-print-mobile",
    });
    const db = getFirestore();
    (dbRef as any).current = db;
  }, []);

  const firestoreFetch = async () => {
    const querySnapshot = await getDocs(
      collection(dbRef?.current as Firestore, "products")
    );
    const documents = querySnapshot.docs.map((doc) => doc.data());
    return documents;
  };
  return { firestoreFetch };
};
