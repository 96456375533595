import { initialPriceState } from "components/model";
import { OrderBag, PRICES, Product } from "models/model";
export interface OptionList {
  options: string[];
  label?: string;
}

export interface PricedOption {
  size: string;
  price: string;
}

export const getPricedOptions = (sizedPrice: string) => {
  if (!sizedPrice) return null;
  const prices = sizedPrice.split(", ").filter((p) => !!p);
  const pricedOption: PricedOption[] = prices.map((priceSize) => {
    const [size, price] = priceSize.split(":");
    return { size, price };
  });
  return pricedOption;
};

export const getOptionsLists = ({ options1, options2, options3 }: Product) => {
  const optionsLists = [options1, options2, options3]
    .filter((o) => !!o)
    .map((options: string) => {
      const keySplit = options.split(":");
      if (keySplit.length > 1) {
        const optionList: OptionList = {
          options: keySplit[1].split(", ").filter((o) => !!o),
          label: keySplit[0],
        };
        return optionList;
      } else {
        const optionList: OptionList = {
          options: keySplit[0].split(", ").filter((o) => !!o),
        };
        return optionList;
      }
    });

  return optionsLists;
};

export const getSum = (
  product: Product,
  state: typeof initialPriceState,
  pricedOptions: PricedOption[] | null
) => {
  let sum = 0;
  if (pricedOptions) {
    const option = pricedOptions.find(
      (p) => p.size === state.pricedOption
    )?.price;
    if (option) sum += +option;
  } else {
    sum += product.price;
  }
  if (state.isGiftAdded) sum += PRICES.GIFT;
  if (state.isTextAdded) sum += PRICES.ADDTEXT;
  return sum * +state.count;
};

export const getBagSum = (bag: OrderBag) => {
  let bagSum = 0;
  bag.orderItems.forEach((item) => {
    let itemSum = item.pricedOption?.price || item.product.price;
    item.addons.forEach((addon) => {
      itemSum += PRICES[addon];
    });
    itemSum *= item.count;
    bagSum += itemSum;
  });
  return bagSum;
};

export const getIsAddEnabled = (
  state: typeof initialPriceState,
  optionsLists: OptionList[],
  pricedOptions: PricedOption[] | null
) => {
  const missingOption1 = optionsLists[0]?.options.length && !state.option1;
  const missingOption2 = optionsLists[1]?.options.length && !state.option2;
  const missingOption3 = optionsLists[2]?.options.length && !state.option3;
  const missingPricedOption = pricedOptions?.length && !state.pricedOption;
  const isZero = !+state.count
  return [
    missingOption1,
    missingOption2,
    missingOption3,
    missingPricedOption,
    isZero,
  ].some((o) => !!o);
};
