import { Checkbox, styled } from "@mui/material";
import { COLORS, PRICES } from "models/model";
import { AddTextRowProps } from "./model";

export const StyledCheckbox = styled(Checkbox)({
  paddingRight: 0,
});

export const StyledTextArea = styled("textarea")({
  resize: "none",
  padding: 8,
  borderColor: COLORS.dividerGray,
  direction: "rtl",
  width: "100%",
  boxSizing: "border-box",
});

export const PriceRow = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  direction: "rtl",
});

export const AddTextRow = ({
  isTextAdded,
  onToggleAddText,
  onChange,
}: AddTextRowProps) => {
  return (
    <>
      <PriceRow>
        <div>
          <StyledCheckbox checked={isTextAdded} onChange={onToggleAddText} />
          <span style={{ fontSize: 13, lineHeight: "40px" }}>
            הוספת כיתוב ✏️
          </span>
        </div>
        {isTextAdded && <div>₪ {PRICES.ADDTEXT}</div>}
      </PriceRow>
      {isTextAdded && (
        <textarea
          dir="rtl"
          className="w-full my-2 p-2 border border-violet-300-300 rounded outline-1 outline-purple-900"
          onChange={(e) => onChange(e.currentTarget.value)}
          placeholder="הוסף כיתוב"
        />
      )}
    </>
  );
};
