export const COLORS = {
  textGray: "#555555",
  black: "#00000",
  pink: "#d15a5a",
  dividerGray: "#f3f3f3",
  green: "#6cc063",
  purple: "#5a3472",
};

export const PRICES = {
  GIFT: 5,
  ADDTEXT: 10,
};

export const ROUTES = {
  EXPANDED_ITEM: "expanded-item",
};

export enum ADDONS {
  GIFT = "GIFT",
  ADDTEXT = "ADDTEXT",
}

export const ADDON_TEXTS = {
  [ADDONS.GIFT]: "אריזת מתנה",
  [ADDONS.ADDTEXT]: "הוספת כיתוב",
};

export interface Product {
  order: number;
  image: string;
  image2?: string;
  image3?: string;
  image4?: string;
  name: string;
  description: string;
  price: number;
  sizes: string;
  sizedPrice: string;
  options1: string;
  options2: string;
  options3: string;
  isFull: boolean;
}

export interface PricedOption {
  key: string;
  price: number;
}
export interface OrderItem {
  product: Product;
  addons: ADDONS[];
  textAddonString?: string;
  count: number;
  options: string[];
  itemText?: string;
  comments?: string;
  pricedOption: PricedOption | null;
}

export interface OrderBag {
  orderItems: OrderItem[];
  city: string;
  fullName: string;
}
