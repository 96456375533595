import { SwitchBaseProps } from "@mui/material/internal/SwitchBase";
import { PRICES } from "models/model";
import { getOptionsLists, getSum, OptionList } from "utils/utils";
import { AddTextRow, PriceRow, StyledCheckbox } from "./AddonRows";
import { CountSelect } from "./CountSelect";
import { PricingSectionProps } from "./model";
import ToggleOptions from "./ToggleOptions";

export const PricingSection = ({
  product,
  pricedOptions,
  state,
  dispatch,
}: PricingSectionProps) => {
  const optionsLists = getOptionsLists(product);
  const priceOptionsList: OptionList = {
    label: "מידה",
    options: (pricedOptions || []).map((p) => p.size),
  };

  const onToggleAddText: SwitchBaseProps["onChange"] = (_e, checked) => {
    dispatch({ type: "isTextAdded", value: checked });
  };
  const onChange = (value: string) => {
    dispatch({ type: "itemText", value });
  };

  const setCountValue = (value: string) => {
    dispatch({
      type: "count",
      value: +value,
    });
  };

  return (
    <>
      {pricedOptions && (
        <PriceRow>
          <ToggleOptions
            {...{
              optionList: priceOptionsList,
              id: "pricedOption",
              option: state.pricedOption,
              setOption: (value) =>
                dispatch({
                  type: "pricedOption",
                  value: value,
                }),
            }}
          />
          {state.pricedOption && (
            <div>
              ₪{" "}
              {pricedOptions?.find((p) => p.size === state.pricedOption)?.price}
            </div>
          )}
        </PriceRow>
      )}
      {optionsLists[0] && (
        <PriceRow>
          <ToggleOptions
            {...{
              optionList: optionsLists[0],
              id: "option1",
              option: state.option1,
              setOption: (value) => dispatch({ type: "option1", value }),
            }}
          />
          {product.sizedPrice ? "" : <div>₪ {product.price}</div>}
        </PriceRow>
      )}
      {optionsLists[1] && (
        <PriceRow>
          <ToggleOptions
            {...{
              optionList: optionsLists[1],
              id: "option2",
              option: state.option2,
              setOption: (value) => dispatch({ type: "option2", value }),
            }}
          />
        </PriceRow>
      )}
      {optionsLists[2] && (
        <PriceRow>
          <ToggleOptions
            {...{
              optionList: optionsLists[2],
              id: "option3",
              option: state.option3,
              setOption: (value) => dispatch({ type: "option3", value }),
            }}
          />
        </PriceRow>
      )}
      <PriceRow>
        <div>
          <StyledCheckbox
            checked={state.isGiftAdded}
            onChange={() =>
              dispatch({ type: "isGiftAdded", value: !state.isGiftAdded })
            }
          />
          <span style={{ fontSize: 13, lineHeight: "40px" }}>
            אריזת מתנה 🎁
          </span>
        </div>
        {state.isGiftAdded && <div>₪ {PRICES.GIFT}</div>}
      </PriceRow>
      <AddTextRow
        {...{ isTextAdded: state.isTextAdded, onToggleAddText, onChange }}
      />
      <PriceRow style={{ height: 90 }}>
        <CountSelect {...{ value: state.count, setValue: setCountValue }} />
        <div style={{ width: "100%" }}>
          <p
            style={{
              color: "black",
              fontSize: 17,
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            ₪ {getSum(product, state, pricedOptions)}
          </p>
        </div>
      </PriceRow>
    </>
  );
};
