import { SwitchBaseProps } from "@mui/material/internal/SwitchBase";
import { OrderBag, OrderItem, Product } from "models/model";
import { PricedOption } from "utils/utils";

export interface Category {
  img: string;
  label: string;
  id: string;
  subCategories?: Category[];
}

export const categories: Category[] = [
  { img: "discount", label: "מבצעים", id: "sale" },
  { img: "apple", label: "פירות", id: "fruit" },
  { img: "broccoli", label: "ירקות", id: "vegetable" },
];

export interface ChipData {
  key: number;
  label: string;
}

export const initialPriceState = {
  count: "1",
  isGiftAdded: false,
  isTextAdded: false,
  itemText: "",
  option1: "",
  option2: "",
  option3: "",
  pricedOption: "",
};

export type State = typeof initialPriceState;
export type Dispatch = React.Dispatch<any>;

interface StateProps {
  state: State;
  dispatch: Dispatch;
}

export interface HeaderProps {
  categories: Category[];
  setItemListTop: React.Dispatch<React.SetStateAction<number>>;
  cartButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  selectedCategory: string | null;
  bag: OrderBag;
  setBag: React.Dispatch<React.SetStateAction<OrderBag>>;
  onSendOrder: () => void;
}

export interface ItemListProps {
  itemListTop: number;
  selectedCategoryId: string | null;
  categories: Category[];
  products: Product[];
  onAddToOrder: (order: OrderItem) => void;
}

export interface ExpandedItemProps {
  expandedItem: Product | null;
  onCloseItem: () => void;
  onAddToOrder: (order: OrderItem) => void;
}

export interface PricingSectionProps extends StateProps {
  product: Product;
  pricedOptions: PricedOption[] | null;
}

export interface AddTextRowProps {
  isTextAdded: boolean;
  onChange: (v: string) => void;
  onToggleAddText: SwitchBaseProps["onChange"];
}

export const products: Product[] = [];
