import styled from "@emotion/styled";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { IconButton, TextField } from "@mui/material";

const CountInput = styled(TextField)`
  width: 60px;
  & input {
    text-align: center;
  }
`;

interface CountSelectProps {
  value: string;
  setValue: (value: string) => void;
}

export const CountSelect = ({ value, setValue }: CountSelectProps) => {
  return (
    <div dir="ltr" className="flex items-center">
      <IconButton onClick={() => setValue(+value + 1 + "")}>
        <PlusCircleIcon className="w-6 h-6 text-slate-400" />
      </IconButton>
      <div className="relative">
        <CountInput
          type="number"
          inputProps={{ dir: "rtl" }}
          dir="rtl"
          onChange={(e) => setValue(e.target.value)}
          value={value || ""}
          id="outlined-basic"
          variant="outlined"
          size="small"
        />
        <div className="absolute text-center w-full text-xs text-slate-600">כמות</div>
      </div>
      <IconButton onClick={() => setValue(+value - 1 + "")}>
        <MinusCircleIcon className="w-6 h-6 text-slate-400" />
      </IconButton>
    </div>
  );
};
