import { useReducer } from "react";

export const useReducerWrapper = <T>(initialState: T) => {
  type State = typeof initialState;
  type Actionkeys = keyof State;

  interface Action {
    type: Actionkeys;
    value: any;
  }

  const reduce = (state: State, action: Action): State => {
    return { ...state, [action.type]: action.value };
  };

  const reducer = (state: State, action: Action) => {
    return reduce(state, action);
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch } as {
    state: State;
    dispatch: React.Dispatch<Action>;
  };
};
