import { Autocomplete, Button, TextField } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// const top100Films = [
//   "אופקים",
//   "אור יהודה",
//   "אור עקיבא",
//   "אילת",
//   "אלעד",
//   "אריאל",
//   "אשדוד",
//   "אשקלון",
//   "באר שבע",
//   "בית שאן",
//   "בית שמש",
//   "ביתר עילית",
//   "בני ברק",
//   "בת ים",
//   "גבעת שמואל",
//   "גבעתיים",
//   "דימונה",
//   "הוד השרון",
//   "הרצליה",
//   "חדרה",
//   "חולון",
//   "חיפה",
//   "טבריה",
//   "טירת כרמל",
//   "יבנה",
//   "יהוד-מונוסון",
//   "יקנעם",
//   "ירושלים",
//   "כפר סבא",
//   "כרמיאל",
//   "לוד",
//   "מגדל העמק",
//   "מודיעין עילית",
//   "מודיעין-מכבים-רעות",
//   "מעלה אדומים",
//   "מעלות-תרשיחא",
//   "נהריה",
//   "נס ציונה",
//   "נצרת עילית",
//   "נשר",
//   "נתיבות",
//   "נתניה",
//   "עכו",
//   "עפולה",
//   "ערד",
//   "פתח תקווה",
//   "צפת",
//   "קריית אונו",
//   "קריית אתא",
//   "קריית ביאליק",
//   "קריית גת",
//   "קריית ים",
//   "קריית מוצקין",
//   "קריית מלאכי",
//   "קריית שמונה",
//   "ראש העין",
//   "ראשון לציון",
//   "רחובות",
//   "רמלה",
//   "רמת גן",
//   "רמת השרון",
//   "רעננה",
//   "שדרות",
//   "תל אביב-יפו",
// ];

const cities = [
  "אור יהודה",
  "אלעד",
  "אריאל",
  "אשדוד",
  "אשקלון",
  "בני ברק",
  "בת ים",
  "גבעת שמואל",
  "גבעתיים",
  "הוד השרון",
  "כפר סבא",
  "הרצליה",
  "חולון",
  "יהוד-מונוסון",
  "מודיעין-מכבים-רעות",
  "פתח תקווה",
  "קריית אונו",
  "ראש העין",
  "ראשון לציון",
  "רחובות",
  "רמת גן",
  "רמת השרון",
  "רעננה",
  "תל אביב-יפו",
];

interface SendOrderFormProps {
  onSendOrder: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onChangeCity: (v: string | null) => void;
  onChangeFullName: (v: string) => void;
}

const SendOrderForm = ({
  onSendOrder,
  setStep,
  onChangeCity,
  onChangeFullName,
}: SendOrderFormProps) => {
  return (
    <div className="w-screen max-w-full p-4">
      <div dir="rtl" className="flex flex-col items-center">
        <p className="text-3xl font-bold text-sky-700">
          שימו <span className="text-xl">❤️</span>
        </p>
        <p className="py-4 font-sans font-bold">
          ההזמנה מתבצעת בווטסאפ <WhatsAppIcon className="text-green-700" />
        </p>
        <p className="text-center">
          יש לשלוח את ההודעה האוטומטית הכוללת את פרטי ההזמנה
        </p>
        <input
          className="mt-4 border rounded py-2 px-3 text-gray-700 leading-tight"
          placeholder="שם מלא"
          onChange={(e) => onChangeFullName(e.currentTarget.value)}
        />
        <Autocomplete
          dir="rtl"
          onChange={(e, v) => onChangeCity(v)}
          size="small"
          className="w-64 p-4"
          id="combo-box-demo"
          options={cities}
          renderInput={(params) => (
            <TextField
              {...params}
              dir="rtl"
              label="עיר למשלוח"
              helperText="כרגע ניתן לבצע משלוח לערים אילו בלבד"
            />
          )}
        />
      </div>
      <div className="flex justify-center">
        <Button variant="text" onClick={() => setStep(0)}>
          חזרה
        </Button>
        <Button disableElevation variant="contained" onClick={onSendOrder}>
          שלח בווטסאפ
        </Button>
      </div>
    </div>
  );
};
export default SendOrderForm;
