import { ADDON_TEXTS, OrderBag, PRICES } from "models/model";

interface Message {
  count: number;
  name: string;
  price: number;
}

export const _generateMessage = ({ count, name, price }: Message) => {
  return `הזמנה מס׳ 384630 
  ו - - - - - - - - - - - - - - - - - - - - - - - -
  0.5 ק׳   עגבניות
  ₪21     (מק״ט 7290000408354) | ₪1
  
  1.5 ק׳   מלפפון
  ₪26     (מק״ט 7290006664990) | ₪13
  
  1 יח׳     שמן
  ₪26     (מק״ט 7290006664990) | ₪96
  
  2 יח׳     ענבים שחורים שקיל טעים מאוד
  ₪26     (מק״ט 7290006664990) | ₪13
  
  25 יח׳   דנונה יוגורט עם תוספת
                פצפוצים 173 גרם
  ₪26     (מק״ט 7290006664990) | ₪123
  ו - - - - - - - - - - - - - - - - - - - - - - - - 
  ₪477   לתשלום`;
};

function esc(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const generateMessage = (bag: OrderBag) => {
  let total = 0;
  let message = "";
  const COUNT_CHARS = 10;

  message += `\n*סיכום הזמנה*`;
  message += ` - ${bag.fullName}\n`;

  bag.orderItems.forEach((o) => {
    const { name } = o.product;
    const price = o.pricedOption ? o.pricedOption.price : o.product.price;
    const countSpaces = " ".repeat(COUNT_CHARS - price.toString().length * 2);
    message += `\n*₪${price}${countSpaces}${esc(name)}*`;
    if (o.count > 1) message += ` *x${o.count}*`;

    o.addons.forEach((addon) => {
      const addonSpace = " ".repeat(
        COUNT_CHARS - PRICES[addon].toString().length * 2
      );
      message += `\n₪${PRICES[addon]}${addonSpace}${esc(ADDON_TEXTS[addon])}`;
      total += PRICES[addon] * o.count;
    });

    if (o.options.filter((o) => !!o).length)
      message += `\nסוג: ${o.options.join("")}`;
    if (!!o.itemText) message += `\n${o.itemText}`;
    if (!!o.pricedOption) message += `\n${o.pricedOption.key}`;
    if (!!o.comments) message += `\nהערה למוצר ״${o.comments}״`;

    total += price * o.count;
    message += "\n";
  });

  message += `\n*עיר למשלוח*   ${bag.city}`;
  message += `\n*סה״כ*             ₪${total}`;
  return message;
};
