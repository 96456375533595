import React from "react";
import { Product } from "models/model";

interface GridItemProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  index: number;
  product: Product;
  dimensions: { width: number; height: number };
}

export const GridItem = ({ onClick, product }: GridItemProps) => {
  const transparent = "w-full aspect-square object-contain p-3";
  const full = "w-full aspect-square rounded-2xl object-cover";
  return (
    <div onClick={onClick} className="flex flex-col font-medium text-dura-900">
      <div className="bg-dura-200 rounded-2xl">
        <img
          src={product.image}
          alt={product.image}
          className={product.isFull ? full : transparent}
        />
      </div>

      <div className="h-max grow flex justify-between p-2 text-sm md:text-lg">
        <span dir="ltr" className="text-center">
          {product.name}
        </span>
        <span className="text-dura-800">
          <span style={{ fontSize: 12 }}>₪</span>
          {product.price}
        </span>
      </div>
    </div>
  );
};
