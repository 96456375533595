import styled from "@emotion/styled";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from "@mui/material";
import { OptionList } from "utils/utils";

interface ToggleOptionsProps {
  optionList: OptionList;
  id: string;
  setOption: (value: string) => void;
  option: string;
}
const ToggleButtonGroup = styled(MuiToggleButtonGroup)`
  overflow: scroll;
  max-width: 72vw;
  margin: 5px 0;
`;

const ToggleOptions = ({
  optionList,
  option,
  setOption,
}: ToggleOptionsProps) => {
  const isOverflow = optionList.options.length > 4;
  return (
    <div className="flex flex-row-reverse items-center">
      {isOverflow && <ChevronLeftIcon className="h-5 w-5 text-slate-400" />}
      <ToggleButtonGroup
        dir="ltr"
        color="primary"
        value={option}
        exclusive
        onChange={(_e, value) => setOption(value)}
        aria-label="text alignment"
      >
        {optionList.options.map((option) => (
          <ToggleButton
            style={{ whiteSpace: "nowrap" }}
            key={option}
            value={option}
          >
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {isOverflow && <ChevronRightIcon className="h-5 w-5 text-slate-400" />}
    </div>
  );
};

export default ToggleOptions;
