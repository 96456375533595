import { Badge, IconButton, styled } from "@mui/material";
import { HeaderProps } from "./model";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Cart } from "./Cart";
import { useState } from "react";
import { ReactComponent as LogoSvg } from "../imgs/template.svg";

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    right: 2,
    top: 5,
    border: `2px solid white`,
    padding: "0 4px",
    background: "#d15a5a",
    color: "white",
  },
});

const Header = ({ bag, setBag, onSendOrder, cartButtonRef }: HeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event?.currentTarget);
  };

  return (
    <div className="w-full h-20 flex justify-center">
      <IconButton
        ref={cartButtonRef}
        onClick={onClick}
        aria-label="upload picture"
        className="m-5 fixed bg-dura-200 drop-shadow-md"
        style={{ right: 0 }}
      >
        <StyledBadge badgeContent={bag.orderItems.length}>
          <ShoppingBagIcon className="text-purple-900" />
        </StyledBadge>
      </IconButton>
      <LogoSvg className="w-24 h-auto" />
      <Cart
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        bag={bag}
        setBag={setBag}
        onSendOrder={onSendOrder}
      />
    </div>
    // <AppBar elevation={0} style={{ background: "white" }}>
    //   <Toolbar disableGutters className={"classes.toolbar"}>
    //     <Typography
    //       sx={{
    //         textAlign: "center",
    //         flexGrow: 1,
    //         fontSize: 22,
    //         fontWeight: 100,
    //         letterSpacing: 3,
    //         color: "#403737",
    //         textTransform: "capitalize",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <LogoSvg style={{ width: 100, height: "auto" }} />
    //     </Typography>
    //     <IconButton
    //       ref={cartButtonRef}
    //       onClick={onClick}
    //       aria-label="upload picture"
    //       style={{ position: "absolute", right: 0 }}
    //     >
    //       <StyledBadge badgeContent={bag.orderItems.length}>
    //         <ShoppingBagIcon className="text-purple-900" />
    //       </StyledBadge>
    //     </IconButton>
    //   </Toolbar>
    //   <div className="w-full h-px bg-slate-200" />
    //   <Cart
    //     anchorEl={anchorEl}
    //     setAnchorEl={setAnchorEl}
    //     bag={bag}
    //     setBag={setBag}
    //     onSendOrder={onSendOrder}
    //   />
    // </AppBar>
  );
};

export default Header;
