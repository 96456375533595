import "../App.css";
import { useEffect, useRef, useState } from "react";
import { useFirestore } from "hooks/useFirestore";
import { OrderBag, OrderItem, Product } from "models/model";
import { generateMessage } from "utils/message-generator";
import Header from "components/Header";
import ItemList from "components/ItemList";
import { categories } from "components/model";

function App() {
  const [itemListTop, setItemListTop] = useState(0);
  const [products, setProducts] = useState<Product[]>([]);
  const [bag, setBag] = useState<OrderBag>({
    orderItems: [],
    city: "",
    fullName: "",
  });
  const { firestoreFetch } = useFirestore();
  const [selectedCategoryId] = useState<string | null>(null);
  const cartButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const res = await firestoreFetch();
    const orderedProducts = ([...res] as Product[]).sort(
      (a, b) => b.order ? a.order - b.order : -1
    );
    console.log(orderedProducts);
    setProducts(orderedProducts as Product[]);
  };

  const onAddToOrder = (orderItem: OrderItem) => {
    setBag((bag) => ({
      ...bag,
      orderItems: [...bag.orderItems, orderItem],
    }));
    cartButtonRef.current?.click();
  };

  const onSendOrder = () => {
    const message = generateMessage(bag);
    const encoded = encodeURI(message);
    let number = "972544467238";
    // number = "972584565678";
    window.open(`https://wa.me/${number}?text=${encoded}`, "_blank");
  };

  return (
    <div className="App flex-col items-center">
      <Header
        {...{
          bag,
          setBag,
          categories,
          setItemListTop,
          onSendOrder,
          cartButtonRef,
          selectedCategory: selectedCategoryId,
        }}
      />
      <ItemList
        {...{
          onAddToOrder,
          itemListTop,
          selectedCategoryId,
          categories,
          products,
        }}
      />
    </div>
  );
}

export default App;
